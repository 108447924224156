import React from "react";

function Footer() {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 col-md-6"></div>

              <div className="col-lg-4 col-md-6">
                <div className="widget footer-widget">
                  <div className="footer-logo">
                    <img src="assets/img/logo.png" alt="Logo" />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6"></div>

              <div className="col-lg-4 col-md-6">
                <div className="widget footer-widget">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul className="nav-widget clearfix">
                    <li>
                      <a href="Home">Home</a>
                    </li>
                    <li>
                      <a href="About">About Us</a>
                    </li>
                    <li>
                      <a href="Rooms">Rooms</a>
                    </li>
                    <li>
                      <a href="Contact-Us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="widget footer-widget">
                  <h4 className="widget-title">Contact Details</h4>
                  <ul className="nav-widget clearfix">
                    <li>
                      <a href="https://www.google.com/maps/place/Pembroke+Group/@11.0161366,76.957765,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba859c6a4ced4df:0xa94ed52cbdd98d42!8m2!3d11.0161361!4d76.9599488">
                        460/17, Ponne street, Cross cut road, Coimbatore -
                        641012
                      </a>
                    </li>
                    <li>
                      <a href="tel:7200914494">7200914494</a>
                    </li>
                    <li>
                      <a href="mailto:pembrokestaysinfo@gmail.com">
                        pembrokestaysinfo@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="widget footer-widget">
                  <h4 className="widget-title">Social Media Links</h4>
                  <ul className="social-icons">
                    <li>
                      <a href="https://www.facebook.com/pembrokestays">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/+917200914494">
                        <i className="fab fa-whatsapp" />
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/pembroke_stays?igshid=YmMyMTA2M2Y=">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <a href="Home" className="back-to-top">
              <i className="far fa-angle-up" />
            </a>
            <div className="col-md-12">
              <p className="copy-right text-right">
                Copyright ©2022. All Rights Reserved Designed By{" "}
                <a
                  className="nav-widget clearfix"
                  href="https://dailydaysolutions.com/"
                >
                  Daily Day Solutions
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
