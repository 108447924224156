import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function Home() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);
  
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "inherit", // Use the inherit color
  };
  let [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    inputs.hotel_name = hotel_name;
    event.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE}/booking/`, inputs)
        .then(function (response) {
          window.alert("Thank You! We will Contact You Soon");
        });
      setShow(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [show, setShow] = useState(false);
  const [hotel_name, sethotel_name] = useState();
  const handleClose = () => setShow(false);

  // eslint-disable-next-line
  const handleShow = (hotel_name) => {
    setShow(true);
    sethotel_name(hotel_name);
  };

  const [top_stays, settop_stays] = useState([]);
  useEffect(() => {
    gettop_stays();
  }, []);

  const gettop_stays = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/top_stays/`)
        .then(function (response) {
          settop_stays(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let top_stays_html_data = top_stays.map((top_stays, key) => (
    <div key={key} className="col-md-4">
      <div className="single-room">
        <Link to={`/${top_stays.master_id}`}>
          <div className="room-thumb">
            <Image
              src={`.${top_stays.image_path.substring(1, 1000)}`}
              alt="image"
            />
          </div>
        </Link>
        <div className="room-desc">
          <div className="room-cat">
            <p>{top_stays.category}</p>
          </div>
          <h4>{top_stays.hotel_name}</h4>
          <p style={{ textAlign: "justify" }}>{top_stays.description}</p>
          <ul className="room-info list-inline">
            <li>
              <i className="far fa-bed" />
              {top_stays.No_of_bed_rooms} Bed Rooms
            </li>
            <li>
              <i className="far fa-bath" />
              {top_stays.No_of_bath_rooms} Bath Rooms
            </li>
          </ul>
          <Link
            to={`/${top_stays.master_id}`}
            type="submit"
            className="btn filled-btn"
            style={{ position: "relative", top: "20px", padding: "10px" }}
          >
            More Details
            <i className="far fa-long-arrow-right" />
          </Link>
          <Button
            type="submit"
            className="btn filled-btn"
            style={{
              position: "relative",
              top: "25px",
              padding: "10px",
              left: "10px",
            }}
            /*  onClick={() => handleShow(top_stays.hotel_name)} */
          >
            <Link to="/Contact-Us" style={linkStyle}>
              Book Now
            </Link>
            <i className="far fa-long-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  ));

  const [banner_data, setbanner_data] = useState([]);
  useEffect(() => {
    getbanner_data();
  }, []);

  const getbanner_data = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/banner/`)
        .then(function (response) {
          setbanner_data(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let banner_html_data = banner_data.map((banner, key) => (
    <div
      key={key}
      className="single-hero-slide bg-img-center d-flex align-items-center text-center"
      style={{
        backgroundImage: `url(.${banner.image_path.substring(1, 1000)})`,
      }}
    >
      <div className="container">
        <div className="slider-text">
          <span
            className="small-text"
            data-animation="fadeInDown"
            data-delay=".3s"
          >
            {banner.sub_tittle}
          </span>
          <h1 data-animation="fadeInLeft" data-delay=".6s">
            {banner.main_tittle}
          </h1>
          <a
            className="btn filled-btn"
            href="Rooms"
            data-animation="fadeInUp"
            data-delay=".9s"
          >
            Book Rooms <i className="far fa-long-arrow-right" />
          </a>
        </div>
      </div>
    </div>
  ));

  const [clients, setclients] = useState([]);
  useEffect(() => {
    getclients();
  }, []);

  const getclients = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/clients/`)
        .then(function (response) {
          setclients(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  // eslint-disable-next-line
  let clients_html_data = clients.map((clients, key) => (
    <div key={key} className="col-lg-2">
      <div className="brand-item text-center">
        <Image src={`.${clients.image_path.substring(1, 1000)}`} alt="image" />
      </div>
    </div>
  ));

  const images = [
    "assets/img/Home-1.jpg",
    "assets/img/Home-2.jpg",
    "assets/img/Home-3.jpg",
    "assets/img/Home-4.jpg",
    "assets/img/Home-5.jpg",
    "assets/img/Home-6.jpg",
    "assets/img/Home-7.jpg",
    "assets/img/Home-8.jpg",
    "assets/img/Home-9.jpg",
    "assets/img/Home-10.jpg",
    "assets/img/Home-11.jpg",
    "assets/img/Home-12.jpg",
    // Add more image URLs here
  ];

  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Allow infinite loop
    speed: 1000, // Animation speed (in milliseconds)
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (in milliseconds)
    fade: true, // Use fade animation
    cssEase: "linear", // Animation easing
  };

  const handleDragStart = (e) => e.preventDefault();



  return (
    <div>
      <Helmet>
        <script src="assets/js/main.js" type="text/javascript" />
        <title>TOP HOMESTAY & RESORT BOOKING AGENCY ACROSS INDIA </title>
        <meta
          name="description"
          content="Experience luxury stay worldwide with PEMBROKESTAYS.COM, the Best Homestay and Resort Booking agency. Book your dream vacation on any budget. Book now!"
        />
      </Helmet>
      <div>
        <section className="hero-section" id="heroSlideActive">
          <div style={{ width: "100%" }}>
            <AliceCarousel
              mouseTracking
              items={banner_html_data}
              disableDotsControls={true}
              disableButtonsControls={true}
              autoPlay={true}
              infinite={true}
              animationDuration={1000}
              autoPlayInterval={2000}
            />
          </div>
        </section>
        <section className="welcome-section section-padding">
          <div className="container">
            <div className="row align-items-center no-gutters">
              <div className="col-lg-6">
                <div className="tile-gallery">
                  <img
                    src="images/CA-2.jpg"
                    alt="Tile Gallery"
                  />
                  <div className="tile-gallery-content">
                    <div className="tile-icon" style={{ marginBottom: "15px" }}>
                      <img src="assets/img/icons/hostel-hover.png" alt="" />
                    </div>
                    <h3>Pembroke Stays</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <div className="section-title">
                  <span className="title-top with-border">About Us</span>
                  <h1>Welcome To Pembroke Stays</h1>
                  <p>
                    The best homestay and resort booking agency Pembroke Stays
                    offers you the charm of the homestay holidays with the
                    easiness of booking them. A cool breeze during the hot
                    summers, time away from mankind, and being one with nature
                    available just a click away.
                  </p>
                </div>
                <div className="counter">
                  <div className="row">
                    <div className="col-4">
                      <div
                        className="counter-box wow"
                        data-wow-duration="100ms"
                        data-wow-delay="0ms"
                      >
                        <img src="assets/img/icons/building.png" alt="" />
                        <span className="new-counter">75</span>
                        <p>No of Rooms</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className="counter-box wow"
                        data-wow-duration="1500ms"
                        data-wow-delay="0ms"
                      >
                        <img src="assets/img/icons/hostel.png" alt="" />
                        <span className="new-counter">11</span>
                        <p>No of Properties</p>
                      </div>
                    </div>
                    {/*                     <div className="col-4">
                      <div className="counter-box wow fadeInRight animated" data-wow-duration="1500ms" data-wow-delay="800ms">
                        <img src="assets/img/icons/trophy.png" alt="" />
                        <span className="counter-number">420</span>
                        <p>No of Customers</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <a
                  href="About"
                  type="submit"
                  className="btn filled-btn"
                  style={{ position: "relative", top: "40px", padding: "10px" }}
                >
                  More Details
                  <i className="far fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="wcu-section section-bg section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-1">
                {/* Section Title */}
                <div className="feature-left">
                  <div className="section-title">
                    <span className="title-top with-border">
                      WHY SHOULD YOU CHOOSE US?
                    </span>
                    <h6>
                      Your time is valuable. When you pick Pembroke stays, we
                      devote time to planning a first-rate experience based on
                      our local knowledge, allowing you to spend your free time
                      as you see fit. And once you're on the road, each day of
                      your personalized schedule is designed to be
                      unforgettable.
                    </h6>
                  </div>
                  <ul className="feature-list">
                    <li
                      className="wow fadeInUp animated"
                      data-wow-duration="1000ms"
                      data-wow-delay="0ms"
                    >
                      <div className="feature-icon">
                        <i className="fas fa-handshake" />
                      </div>
                      <h4>TRUST</h4>
                      <p>
                        Stay with confidence! Pembroke Stays is completely
                        registered by the Tamil Nadu Tourism Department
                        Corporation and is a member of several professional
                        groups. Our guests have been satisfied and is always
                        happy with our service and the properties we suggest for
                        them.{" "}
                      </p>
                    </li>
                    <li
                      className="wow fadeInUp animated"
                      data-wow-duration="1000ms"
                      data-wow-delay="200ms"
                    >
                      <div className="feature-icon">
                        <i className="far fa-award" />
                      </div>
                      <h4>DEDICATION</h4>
                      <p>
                        We understand how valuable your time is, therefore our
                        team is dedicated to providing you with the greatest
                        booking experience possible, from the moment you book
                        your vacation until you return home from our homes.
                      </p>
                    </li>
                    <li
                      className="wow fadeInUp animated"
                      data-wow-duration="1000ms"
                      data-wow-delay="300ms"
                    >
                      <div className="feature-icon">
                        <i className="far fa-spa" />
                      </div>
                      <h4>PERSONAL CARE AND SERVICE </h4>
                      <p>
                        You can rest easy knowing that Pembroke Stays has taken
                        care of finding you a cosy place to stay. Our welcoming,
                        knowledgeable personnel at the property will provide you
                        with the consideration, care, and service you deserve
                        from the time you plan your trip until the time you
                        return. In order for you to continue making reservations
                        with us, we want you to have the finest vacation
                        experience possible and to get the most out of your trip
                        budget.{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <Slider {...settings}>
                  {images.map((imageUrl, index) => (
                    <div key={index}>
                      <div>
                        <img src={imageUrl} alt={`Slide ${index}`} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className="rooms-warp gird-view section-bg section-padding">
          <div className="section-title text-center">
            <span className="title-top">
              <h2>Our Top Selling Rooms</h2>
            </span>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">{top_stays_html_data}</div>
              </div>
            </div>
          </div>
        </section>
        {/*         <section className="feedback-section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <span className="title-top">Clients Feedback</span>
              <h1>Our Satisfaction People Say <br /> About Our Services </h1>
            </div>
            <div className="feadback-slide row" id="feedbackSlideActive">
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>Omnis voluptas assumde est omnis dolor reporibus autem quidam et aut ciise debitiset
                    arerum
                    neces tibus saep on ways feels like ways.</p>
                  <h5 className="feedback-author">James M. Varney</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>At vero eos et accusamu way set iusto odio dignis ducimus qui bpraes enum voluptatum
                    deleniti
                    atque corrupti quos dolores others worlds.</p>
                  <h5 className="feedback-author">David K. Vinson</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>Omnis voluptas assumde est omnis dolor reporibus autem quidam et aut ciise debitiset
                    arerum
                    neces tibus saep on ways feels like ways.</p>
                  <h5 className="feedback-author">James M. Varney</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>At vero eos et accusamu way set iusto odio dignis ducimus qui bpraes enum voluptatum
                    deleniti
                    atque corrupti quos dolores others worlds.</p>
                  <h5 className="feedback-author">David K. Vinson</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>Omnis voluptas assumde est omnis dolor reporibus autem quidam et aut ciise debitiset
                    arerum
                    neces tibus saep on ways feels like ways.</p>
                  <h5 className="feedback-author">James M. Varney</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>At vero eos et accusamu way set iusto odio dignis ducimus qui bpraes enum voluptatum
                    deleniti
                    atque corrupti quos dolores others worlds.</p>
                  <h5 className="feedback-author">David K. Vinson</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>Omnis voluptas assumde est omnis dolor reporibus autem quidam et aut ciise debitiset
                    arerum
                    neces tibus saep on ways feels like ways.</p>
                  <h5 className="feedback-author">James M. Varney</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feedback-box">
                  <p>At vero eos et accusamu way set iusto odio dignis ducimus qui bpraes enum voluptatum
                    deleniti
                    atque corrupti quos dolores others worlds.</p>
                  <h5 className="feedback-author">David K. Vinson</h5>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="contact-section section-padding">
          <div className="container">
            <div className="row align-items-center no-gutters">
              <div className="col-lg-5 offset-lg-1">
                <div className="section-title">
                  <span className="title-top with-border">Contact Us</span>
                </div>
                <div
                  className="contact-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                  style={{ fontSize: "16px" }}
                >
                  <ul>
                    <li>
                      <i className="far fa-map-marker-alt" />
                      460/17, Ponne street, Cross cut road, Coimbatore - 641012
                    </li>
                    <li>
                      <a href>
                        <i className="far fa-envelope-open" />
                        www.pembrokestays@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href>
                        <i className="far fa-phone" />
                        +91 7200914494
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6" style={{ padding: "20px" }}>
                <div className="map-responsive">
                  <iframe
                    title="map_1"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2995568580163!2d76.95776501478825!3d11.016136592159109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859c6a4ced4df%3A0xa94ed52cbdd98d42!2sPembroke%20Group!5e0!3m2!1sen!2sin!4v1672689475992!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*         <section className="brands-section section-bg">
          <div className="container">
          <div className="section-title text-center">
              <span className="title-top">Our Hotel Partners</span>
            </div>
            <div id="" className="row">
              {clients_html_data}
            </div>
          </div>
        </section> */}
      </div>
      <div className="container ">
        <div className="model_box">
          <Modal
            show={show}
            data={hotel_name}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Enter Your Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mb-3">
                <Button variant="secondary" style={{ padding: "5px" }}>
                  Email - pembrokestaysinfo@gmail.com
                  <br></br>
                  Phone - 72009 14494
                </Button>
              </div>
              <form action="" method="">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hotel Name"
                    value={hotel_name}
                    name="hotel_name"
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Mobile Number"
                    name="phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Number Of Guests"
                    name="no_of_guests"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <select
                    className="form-control"
                    name="meal_plan"
                    onChange={handleChange}
                  >
                    <option value="">Choose Meal Plan</option>
                    <option value="CP Plan - Inclusive of Breakfast">
                      CP Plan - Inclusive of Breakfast
                    </option>
                    <option value="MAP Plan - Inclusive of Breakfast and Dinner ">
                      MAP Plan - Inclusive of Breakfast and Dinner
                    </option>
                    <option value="AP Plan - Inclusive of all three meals ">
                      AP Plan - Inclusive of all three meals
                    </option>
                  </select>
                </div>
                <p>Select Check In Date</p>
                <div className="form-group mt-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Select Check In Date"
                    name="check_in_date"
                    onChange={handleChange}
                  />
                </div>
                <p>Select Check out Date</p>
                <div className="form-group mt-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Select Check out Date"
                    name="check_out_date"
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success mt-4"
                  onClick={handleSubmit}
                  style={{ padding: "5px" }}
                >
                  Submit
                </button>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ padding: "5px" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Home;
