import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Rooms from "./components/Rooms";
import RoomDetails from "./components/RoomDetails";
import Contact from "./components/Contact";
import LocationRooms from "./components/LocationRooms";
import RoomLocationDetails from "./components/RoomLocationDetails";
function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Rooms" element={<Rooms />} />
          <Route exact path="/Contact-Us" element={<Contact />} />
          <Route exact path="/Stays/:Resort" element={<LocationRooms />} />
          <Route exact path="/Stays/:Resort/:Locations" element={<RoomLocationDetails />} />
          <Route exact path=":id" element={<RoomDetails />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
