import React from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function RoomLocationDetails() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const myArrayJSON = urlParams.get("myArray");
  const filteredLocaionDetails = JSON.parse(myArrayJSON || "[]");

  let locaionDetails_html_data;
  let page_title;

  if (filteredLocaionDetails.length > 0) {
    const details = filteredLocaionDetails[0];
    locaionDetails_html_data = [];

    page_title = details.page_tittle;

    for (let i = 1; i <= 10; i++) {
      const headingKey = `heading_${i}`;
      const descriptionKey = `description_${i}`;

      if (details[headingKey] && details[descriptionKey]) {
        locaionDetails_html_data.push(
          <div key={i} className="post-details">
            <div className="entry-header">
              <p
                className="entry-title"
                dangerouslySetInnerHTML={{ __html: details[headingKey] }}
              ></p>
            </div>
            <div className="entry-content">
              <p
                dangerouslySetInnerHTML={{ __html: details[descriptionKey] }}
              ></p>
            </div>
          </div>
        );
      }
    }
  } else {
    locaionDetails_html_data = <div>No location details available.</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{page_title}</title>
      </Helmet>

      <div>
        <section className="blog-details-wrapper section-padding section-bg">
          <div className="container">
            <div className="row">{locaionDetails_html_data}</div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RoomLocationDetails;
