import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Contact() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE}/contact/`, inputs)
        .then(function (response) {
          window.alert("Thanks! We will Contact You Soon");
          navigate("/");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [stays, setstays] = useState([]);

  useEffect(() => {
    getstays();
  }, []);

  const getstays = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/stays_with_location/`)
        .then(function (response) {
          setstays(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let stays_html_data = stays.map((stays, key) => (
    <option value={`${stays.hotel_name} ${stays.location_name}`}>
      {stays.hotel_name} - {stays.location_name.toUpperCase()}
    </option>
  ));

  return (
    <div>
      <Helmet>
        <title>
          GET IN TOUCH | BEST PLACE TO STAY | REACH PEMBROKE STAYS TEAM TODAY
        </title>
        <meta
          name="description"
          content="Get in touch with PEMBROKE STAYS. Top Homestay & Resort and expert assistance in planning your next unforgettable getaway. Contact us today!"
        />
      </Helmet>
      <div>
        <section
          className="breadcrumb-area d-flex align-items-center position-relative bg-img-center"
          style={{ backgroundImage: "url(assets/img/banner-9.jpg)" }}
        >
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h1>Contact</h1>
              <ul className="list-inline">
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>
                  <i className="far fa-angle-double-right" />
                </li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
          <h1 className="big-text">Contact</h1>
        </section>

        <section>
          <div>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </section>

        <section className="contact-form">
          <div className="container ">
            <div className="contact-form-wrap section-bg">
              <h2 className="form-title">Please fill out the Form</h2>
              <div className="col-lg-12">
                <p>
                  <b>
                    Are you curious about PEMBROKE STAYS? Fill out the form
                    below and we will respond as soon as possible.
                  </b>
                </p>
              </div>
              <br></br>
              <form>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Enter Your Full Name"
                        id="name"
                        name="name"
                        onChange={handleChange}
                      />
                      <i className="far fa-user-alt" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        id="email"
                        name="email"
                        onChange={handleChange}
                      />
                      <i className="far fa-envelope" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Enter Your Mobile Number"
                        id="website"
                        name="phone"
                        onChange={handleChange}
                      />
                      <i className="fab fa-wordpress" />
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="input-wrap">
                      <select
                        id="website"
                        name="hotel_name"
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Choose The Property
                        </option>
                        {stays_html_data}
                      </select>
                      <i className="fab fa-drop" />
                    </div>
                  </div>

                  <div className="col-md-3 col-12">
                    <div className="input-wrap">
                      <p>Select Check IN Date</p>
                      <input
                        type="date"
                        placeholder="Select Check IN Date"
                        id="website"
                        name="check_in_date"
                        onChange={handleChange}
                      />
                      <i className="fab fa-calender" />
                    </div>
                  </div>

                  <div className="col-md-3 col-12">
                    <div className="input-wrap">
                      <p>Select Check Out Date</p>
                      <input
                        type="date"
                        placeholder="Select Check Out Date"
                        id="website"
                        name="check_out_date"
                        onChange={handleChange}
                      />
                      <i className="fab fa-calender" />
                    </div>
                  </div>

                  <div className="col-md-2 col-12">
                    <p>No of Adults</p>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Adults"
                        id="website"
                        name="adults"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 col-12">
                    <p>No of Childrens</p>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Childrens"
                        id="website"
                        name="childrens"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 col-12">
                    <p>No of Rooms</p>
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Rooms"
                        id="website"
                        name="no_of_rooms"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-wrap text-area">
                      <textarea
                        placeholder="Write Message"
                        defaultValue={""}
                        name="message"
                        onChange={handleChange}
                      />
                      <i className="far fa-pencil" />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn filled-btn"
                      onClick={handleSubmit}
                    >
                      Send Message <i className="far fa-long-arrow-right" />
                    </button>
                  </div>
                </div>

                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>

                <div className="col-lg-7">
                  <p>
                    Please submit your contact information so that PEMBROKE
                    STAYS can contact you via phone calls, text messages, or
                    emails to inform you about our homestays and resorts for
                    your affordable and luxurious stay.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="contact-info-section">
          <div className="container">
            {/* Section Title Start */}
            <div className="row">
              <div className="col-lg-5">
                <div className="section-title">
                  <span className="title-top">Our Contact Details</span>
                </div>
              </div>
              {/*               <div className="col-lg-7">
                <p>
                  Please submit your contact information so that PEMBROKE STAYS
                  can contact you via phone calls, text messages, or emails to
                  inform you about our homestays and resorts for your affordable
                  and luxurious stay.
                </p>
              </div> */}
            </div>
            {/* Section Title End */}
            <div className="contact-info-boxes">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-box">
                    <div className="contact-icon">
                      <i className="far fa-map-marker-alt" />
                    </div>
                    <h4>Address</h4>
                    <p>460/17, Ponne street,Cross cut road,</p>
                    <p>Coimbatore - 641012</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="contact-info-box">
                    <div className="contact-icon">
                      <i className="far fa-envelope-open" />
                    </div>
                    <h4>Email</h4>
                    <p>
                      <a href="mailto:pembrokestaysinfo@gmail.com">
                        pembrokestaysinfo@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mx-auto">
                  <div className="contact-info-box">
                    <div className="contact-icon">
                      <i className="far fa-phone" />
                    </div>
                    <h4>Phone Us</h4>
                    <a href="+91 7200914494">+91 7200914494</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </section>

        <section className="map-responsive">
          <iframe
            title="map_1"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2995568580163!2d76.95776501478825!3d11.016136592159109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859c6a4ced4df%3A0xa94ed52cbdd98d42!2sPembroke%20Group!5e0!3m2!1sen!2sin!4v1672689475992!5m2!1sen!2sin"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </section>
      </div>
    </div>
  );
}

export default Contact;
