import React from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function About() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  return (
    <div>
      <Helmet>
        <title>BEST HOMESTAY & RESORT | THE MAGIC BEHIND PEMBROKE STAYS</title>
        <meta
          name="description"
          content="Experience the ultimate in hospitality and excellence at PEMBROKE STAYS. Our affordable and luxurious homestay and resort offer an unforgettable getaway."
        />
      </Helmet>
      <section
        className="breadcrumb-area d-flex align-items-center position-relative bg-img-center"
        style={{ backgroundImage: "url(assets/img/banner-9.jpg)" }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1>About Us</h1>
            <ul className="list-inline">
              <li>
                <a href="Home">Home</a>
              </li>
              <li>
                <i className="far fa-angle-double-right" />
              </li>
              <li>About us</li>
            </ul>
          </div>
        </div>
        <h1 className="big-text">About</h1>
      </section>
      <section className="about-site section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-title text-right">
                <span className="title-top with-border">About Us</span>
                <h1>
                  Welcome To
                  <br />
                  Pembroke Stays
                </h1>
              </div>
            </div>
            <div className="col-md-6">
              <p className="mb-30">
                We are the best homestay and resort booking agency, a top
                Domestic booking agency in India. We provide affordable and
                luxurious stays for people who are looking for conference
                meetings, business tours, dealer's meets, daily excursions,
                weekend getaways, adventure tours, honeymoon tours, and friends
                and family vacation.
              </p>
            </div>
          </div>
          {/* Section Title End */}
          <div
            className="about-img bg-image-center"
            style={{ backgroundImage: "url(assets/img/PV-4.jpg)",height:'400px',backgroundRepeat:'no-repeat' }}
          />
          <div className="row no-gutters">
            <div className="offset-xl-1 col-xl-5 col-md-6">
              <div className="about-text-box section-bg">
                <h2>Exclusive for Homestay and Resort Bookings</h2>
                <p>
                  You can find scenic homestays & resorts with us. We strive to
                  make our clients' experiences unique and engaging
                </p>
                <a href="Contact-Us" className="btn filled-btn">
                  Contact Us
                  <i className="far fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*         <section className="our-mission section-padding">
          <div className="container">
            <div className="section-title text-center">
              <span className="title-top">Our Mission</span>
              <h1>Who We Are &amp; What We Do <br/></h1>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="mission-box">
                  <div className="mission-bg bg-img-center" style={{backgroundImage: 'url(assets/img/about/02.jpg)'}} />
                  <div className="mission-desc">
                    <h4>Company Mission</h4>
                    <p>Sedut perspiciatis unde omnis iste natu error
                      sit voluptatem accusantium doloremque laudti
                      um totam rem aperiam eaque quae</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="mission-box">
                  <div className="mission-bg bg-img-center" style={{backgroundImage: 'url(assets/img/about/03.jpg)'}} />
                  <div className="mission-desc">
                    <h4>Company History</h4>
                    <p>Sedut perspiciatis unde omnis iste natu error
                      sit voluptatem accusantium doloremque laudti
                      um totam rem aperiam eaque quae</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mx-auto">
                <div className="mission-box">
                  <div className="mission-bg bg-img-center" style={{backgroundImage: 'url(assets/img/about/04.jpg)'}} />
                  <div className="mission-desc">
                    <h4>What We Do?</h4>
                    <p>Sedut perspiciatis unde omnis iste natu error
                      sit voluptatem accusantium doloremque laudti
                      um totam rem aperiam eaque quae</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      <section className="feature-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-box text-center">
                <div className="feature-icon">
                  <img src="assets/img/icons/icon-1.png" alt="Icon" />
                </div>
                <p>Easy Booking</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-box text-center">
                <div className="feature-icon">
                  <img src="assets/img/icons/icon-2.png" alt="Icon" />
                </div>
                <p>Personalized Customer Support</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="single-feature-box text-center">
                <div className="feature-icon">
                  <img src="assets/img/icons/icon-3.png" alt="Icon" />
                </div>
                <p>Luxurious Holiday</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
