import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Rooms() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);
  
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "inherit", // Use the inherit color
  };
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    inputs.hotel_name = hotel_name;
    event.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE}/booking/`, inputs)
        .then(function (response) {
          window.alert("Thank You! We will Contact You Soon");
        });
      setShow(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [stays, setstays] = useState([]);
  useEffect(() => {
    getstays();
  }, []);

  useEffect(() => {
    nonfilterResult();
    // eslint-disable-next-line
  }, [stays]);

  const getstays = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/stays/`)
        .then(function (response) {
          setstays(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [show, setShow] = useState(false);
  const [hotel_name, sethotel_name] = useState();
  const handleClose = () => setShow(false);

  // eslint-disable-next-line
  const handleShow = (hotel_name) => {
    setShow(true);
    sethotel_name(hotel_name);
  };

  const [data, setData] = useState(stays);

  const filterResult = (location_id) => {
    const result = stays.filter((filtered_data) => {
      return filtered_data.location === location_id;
    });
    setData(result);
  };

  const nonfilterResult = () => {
    setData(stays);
  };

  let stays_html_data = data.map((data, key) => (
    <div key={key} className="col-md-4">
      <div className="single-room">
        <Link to={`/${data.id}`}>
          <div className="room-thumb">
            <Image src={`.${data.img_1.substring(1, 1000)}`} alt="image" />
          </div>
        </Link>
        <div className="room-desc">
          <div className="room-cat">
            <p>{data.category}</p>
          </div>
          <h4>{data.hotel_name}</h4>
          <p style={{ textAlign: "justify" }}>{data.description}</p>
          <ul className="room-info list-inline">
            <li>
              <i className="far fa-bed" />
              {data.No_of_bed_rooms} Bed Rooms
            </li>
            <li>
              <i className="far fa-bath" />
              {data.No_of_bath_rooms} Bath Rooms
            </li>
          </ul>
          <Link
            to={`/${data.id}`}
            type="submit"
            className="btn filled-btn"
            style={{ position: "relative", top: "20px", padding: "10px" }}
          >
            More Details
            <i className="far fa-long-arrow-right" />
          </Link>
          <Button
            type="submit"
            className="btn filled-btn"
            style={{
              position: "relative",
              top: "25px",
              padding: "10px",
              left: "10px",
            }}
            /*  onClick={() => handleShow(data.hotel_name)} */
          >
            <Link to="/Contact-Us" style={linkStyle}>
              Book Now
            </Link>
            <i className="far fa-long-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  ));

  const [locations, setlocations] = useState([]);
  useEffect(() => {
    getlocations();
  }, []);

  const getlocations = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/locations/`)
        .then(function (response) {
          setlocations(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let locations_data = locations.map((locations, key) => (
    <li
      key={key}
      onClick={() => {
        filterResult(locations.id);
      }}
    >
      {locations.location}
    </li>
  ));
  return (
    <div>
      <Helmet>
        <title>
          AFFORDABLE RESORT & HOMESTAY | LUXURIOUS RESORT & HOMESTAY| BOOK YOUR
          DREAM GETAWAY NOW!
        </title>
        <meta
          name="description"
          content="Discover a world at PEMBROKE STAYS. Indulge in lavish villas or charming resorts for a one-of-a-kind getaway. Budget Homestay & Resort - Book now!"
        />
      </Helmet>
      <div>
        <section className="rooms-warp gird-view section-bg section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="filter-view">
                  <ul>
                    <li
                      className="active-f-view"
                      onClick={() => {
                        nonfilterResult(locations.id);
                      }}
                    >
                      All Location
                    </li>
                    {locations_data}
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">{stays_html_data}</div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container ">
        <div className="model_box">
          <Modal
            show={show}
            data={hotel_name}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Enter Your Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mb-3">
                <Button variant="secondary" style={{ padding: "5px" }}>
                  Email - pembrokestaysinfo@gmail.com
                  <br></br>
                  Phone - 72009 14494
                </Button>
              </div>
              <form action="" method="">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hotel Name"
                    value={hotel_name}
                    name="hotel_name"
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Mobile Number"
                    name="phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Number Of Guests"
                    name="no_of_guests"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <select
                    className="form-control"
                    name="meal_plan"
                    onChange={handleChange}
                  >
                    <option value="">Choose Meal Plan</option>
                    <option value="CP Plan - Inclusive of Breakfast">
                      CP Plan - Inclusive of Breakfast
                    </option>
                    <option value="MAP Plan - Inclusive of Breakfast and Dinner ">
                      MAP Plan - Inclusive of Breakfast and Dinner
                    </option>
                    <option value="AP Plan - Inclusive of all three meals ">
                      AP Plan - Inclusive of all three meals
                    </option>
                  </select>
                </div>
                <p>Select Check In Date</p>
                <div className="form-group mt-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Select Check In Date"
                    name="check_in_date"
                    onChange={handleChange}
                  />
                </div>
                <p>Select Check out Date</p>
                <div className="form-group mt-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Select Check out Date"
                    name="check_out_date"
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success mt-4"
                  onClick={handleSubmit}
                  style={{ padding: "5px" }}
                >
                  Submit
                </button>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ padding: "5px" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Rooms;
