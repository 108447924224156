import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [locations, setlocations] = useState([]);
  useEffect(() => {
    getlocations();
  }, []);

  const getlocations = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/locations/`)
        .then(function (response) {
          setlocations(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let location_html_data = locations.map((locations, key) => (
    <div key={key}>
      <li>
        <Link to={`Stays/${locations.location}`}>{locations.location}</Link>
      </li>
    </div>
  ));

  useEffect(() => {
    setActive(false);
  }, []);
  const [isActive, setActive] = useState();

  const ToggleClass = () => {
    setActive(!isActive);
  };

  const closeMenu = () => {
    setActive(false);
  };

  return (
    <div>
      <div className="header-top-area section-bg">
        <div className="container-fluid"></div>
      </div>
      <div className="header-menu-area">
        <a href="https://wa.me/+917200914494" className="float">
          <i className="fa fa-whatsapp my-float"></i>
        </a>
        <a href="tel:+917200914494" className="float-1">
          <i className="fa fa-phone my-float"></i>
        </a>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-7">
              <div className="logo">
                <Link to="Home">
                  <img src="assets/img/logo.png" alt="Avson" />
                </Link>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-5">
              <div className="menu-right-area text-right">
                <nav className="main-menu">
                  <ul className="list-inline">
                    <li>
                      <Link to="Home">Home</Link>
                    </li>
                    <li>
                      <Link to="About">About Us</Link>
                    </li>
                    <li className="have-submenu">
                      <Link to="Rooms">Stays</Link>
                      <ul className="submenu">{location_html_data}</ul>
                    </li>
                    <li>
                      <Link to="Contact-Us">Contact Us</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="mobilemenu mean-container">
            <div className="mean-bar">
              <div className="nav_custom">
                <Link
                  to="#nav"
                  className={
                    isActive ? "meanmenu-reveal" : "meanmenu-reveal meanclose"
                  }
                >
                  <i className="far fa-bars" onClick={ToggleClass} />
                </Link>
              </div>
              <nav className="mean-nav">
                <ul
                  className="list-inline"
                  style={isActive ? { display: "block" } : { display: "none" }}
                >
                  <li className="mean-last">
                    <Link to="Home" onClick={closeMenu}>Home</Link>
                  </li>
                  <li className="mean-last">
                    <Link to="About" onClick={closeMenu}>About Us</Link>
                  </li>
                  <li className="mean-last">
                  <li className="have-submenu">
                      <Link to="Rooms">Stays</Link>
                      <ul className="submenu" onClick={closeMenu} >{location_html_data}</ul>
                    </li>
                  </li>
                  <li className="mean-last">
                    <Link to="Contact-Us"onClick={closeMenu} >Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
