import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Modal } from "react-bootstrap";

function RoomDetails() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  let params = useParams();
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE}/booking/`, inputs)
        .then(function (response) {
          window.alert("Thank You! We will Contact You Soon");
        });
      setShow(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [stays, setstays] = useState([]);
  useEffect(() => {
    getstays();
    // eslint-disable-next-line
  }, []);

  const getstays = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/stays/${params.id}`)
        .then(function (response) {
          setstays(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [locaionDetails, setlocaionDetails] = useState([]);

  useEffect(() => {
    if (params.id !== null) {
      getlocaionDetails();
    }
  }, [params.id]);

  const getlocaionDetails = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/locationDetails/`)
        .then(function (response) {
          setlocaionDetails(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let filteredLocaionDetails = locaionDetails.filter(
    (detail) => detail.property_id === params.id
  );
  let page_title;

  if (filteredLocaionDetails.length > 0) {
    const details = filteredLocaionDetails[0];
    page_title = details.page_tittle;
  }

  if (!filteredLocaionDetails || filteredLocaionDetails.length === 0) {
    // If there's no data, you can render a loading message or null
    return <p>Loading...</p>;
  }

  const queryString = new URLSearchParams({
    myArray: JSON.stringify(filteredLocaionDetails),
  }).toString();

  return (
    <div>
      <section className="room-details-wrapper section-padding">
        <div className="container">
          <Helmet>
            <script src="assets/js/slider.js" type="text/javascript" />
            <title>{page_title}</title>
          </Helmet>
          <div className="row">
            <div className="col-lg-8">
              <div className="room-details">
                <div className="entry-header">
                  <div className="post-thumb position-relative">
                    <div className="post-thumb-slider">
                      <div className="main-slider">
                        <div className="single-img">
                          <a
                            href={`.${
                              stays.img_1 ? stays.img_1.substring(1, 1000) : ""
                            }`}
                            className="main-img"
                          >
                            <Image
                              src={`.${
                                stays.img_1
                                  ? stays.img_1.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="single-img">
                          <a
                            href={`.${
                              stays.img_2 ? stays.img_2.substring(1, 1000) : ""
                            }`}
                            className="main-img"
                          >
                            <Image
                              src={`.${
                                stays.img_2
                                  ? stays.img_2.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="single-img">
                          <a
                            href={`.${
                              stays.img_3 ? stays.img_3.substring(1, 1000) : ""
                            }`}
                            className="main-img"
                          >
                            <Image
                              src={`.${
                                stays.img_3
                                  ? stays.img_3.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="single-img">
                          <a
                            href={`.${
                              stays.img_4 ? stays.img_4.substring(1, 1000) : ""
                            }`}
                            className="main-img"
                          >
                            <Image
                              src={`.${
                                stays.img_4
                                  ? stays.img_4.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="single-img">
                          <a
                            href={`.${
                              stays.img_5 ? stays.img_5.substring(1, 1000) : ""
                            }`}
                            className="main-img"
                          >
                            <Image
                              src={`.${
                                stays.img_5
                                  ? stays.img_5.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="dots-slider row">
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_1
                                  ? stays.img_1.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_2
                                  ? stays.img_3.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_3
                                  ? stays.img_3.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_4
                                  ? stays.img_4.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_5
                                  ? stays.img_5.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_1
                                  ? stays.img_1.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_2
                                  ? stays.img_2.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_3
                                  ? stays.img_3.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="single-dots">
                            <Image
                              src={`.${
                                stays.img_4
                                  ? stays.img_4.substring(1, 1000)
                                  : ""
                              }`}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="room-cat">
                    <a href>{stays.category}</a>
                  </div>
                  <h2 className="entry-title">{stays.hotel_name}</h2>
                  <ul className="entry-meta list-inline">
                    <li>
                      <i className="far fa-bed" />
                      {stays.No_of_bed_rooms} Bed Rooms
                    </li>
                    <li>
                      <i className="far fa-bath" />
                      {stays.No_of_bath_rooms} Bath Rooms
                    </li>
                  </ul>
                </div>
                <div className="room-details-tab">
                  <div className="row">
                    <div className="col-sm-3">
                      <ul className="nav desc-tab-item" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#desc"
                            role="tab"
                            data-toggle="tab"
                          >
                            Room Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#location"
                            role="tab"
                            data-toggle="tab"
                          >
                            Contact Details
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-9">
                      <div className="tab-content desc-tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane fade in active show"
                          id="desc"
                        >
                          <h5 className="tab-title">Room Details </h5>
                          <div className="entry-content">
                            <p>{stays.description}</p>
                            <div className="entry-gallery-img">
                              <figure className="entry-media-img">
                                <Image
                                  src={`.${
                                    stays.img_1
                                      ? stays.img_1.substring(1, 1000)
                                      : ""
                                  }`}
                                  alt="image"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="location"
                        >
                          <h5 className="tab-title">Contact Details</h5>
                          <div className="room-location">
                            <div className="row">
                              <div className="col-4">
                                <h6>City</h6>
                                <p>{stays.city}</p>
                              </div>
                              <div className="col-4">
                                <h6>Phone</h6>
                                <p>+91 72009 14494</p>
                              </div>
                              <div className="col-4">
                                <h6>Email</h6>
                                <p>pembrokestays@gmail.com</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content desc-tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane fade in active show"
                          id="desc"
                        >
{/*                           <h5 className="tab-title">Location Details</h5> */}
                          <div className="entry-content">
                            <p style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: filteredLocaionDetails[0].description_1}}>
                            </p>
                          </div>
                          <div>
                            <div>
                              <br></br>
                            </div>
                          </div>
                          <div className="entry-content">
                            <Link
                              to={`/Stays/${params.id}/${filteredLocaionDetails[0].id}?${queryString}`}
                              style={{ textDecorationColor: "87976a" }}
                            >
                              Read More ....
                            </Link>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="location"
                        >
                          <h5 className="tab-title">Contact Details</h5>
                          <div className="room-location">
                            <div className="row">
                              <div className="col-4">
                                <h6>City</h6>
                                <p>{stays.city}</p>
                              </div>
                              <div className="col-4">
                                <h6>Phone</h6>
                                <p>+91 72009 14494</p>
                              </div>
                              <div className="col-4">
                                <h6>Email</h6>
                                <p>pembrokestays@gmail.com</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-wrap">
                <div className="widget banner-widget">
                  <h3 style={{ color: "white" }}>Top Amenities</h3>
                  <br></br>
                  <ul style={{ textAlign: "left", listStyleType: "circle" }}>
                    <li>{stays.amenities_1}</li>
                    <li>{stays.amenities_2}</li>
                    <li>{stays.amenities_3}</li>
                    <li>{stays.amenities_4}</li>
                    <li>{stays.amenities_5}</li>
                  </ul>
                  <a href className="btn filled-btn" onClick={handleShow}>
                    BOOK NOW <i className="far fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container ">
        <div className="model_box">
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Enter Your Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mb-3">
                <Button variant="secondary" style={{ padding: "5px" }}>
                  Email - pembrokestays@gmail.com
                  <br></br>
                  Phone - 72009 14494
                </Button>
              </div>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Name"
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Mobile Number"
                    name="phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Number Of Guests"
                    name="no_of_guests"
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success mt-4"
                  onClick={handleSubmit}
                  style={{ padding: "5px" }}
                >
                  Submit
                </button>
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ padding: "5px" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default RoomDetails;
